<template>
  <!-- 学习记录 -->
  <div class="foxRight">
    <div class="recentLiveTime">
      <div class="navBar">
        <div v-for="(item, index) in week" :key="index" class="week_item">
          {{ item }}
        </div>
        <!-- <div v-for="(item, index) in datelist" :key="index" class="week_item">
          {{ item }}
        </div> -->
      </div>
      <div class="dateBar">
        <div v-for="(item, index) in showDateList" :key="index" class="week_item">
          <span style="cursor: pointer" :class="{
            active: item.day === chooseDay && item.month === chooseMonth,
            'no-this-month': item.month !== chooseMonth,
          }" @click="chooseDate(item)">
            <el-badge v-if="item.detail && item.detail.isLive === 1" is-dot class="badge" type="info">{{ item.day
              }}</el-badge>
            <el-badge v-if="item.detail && item.detail.isLive === 0" is-dot class="badge" type="success">{{ item.day
              }}</el-badge>
            <span v-if="!item.detail">{{ item.day }}</span>
          </span>
        </div>
      </div>
      <!-- <img v-if="showPanal === false" style="margin: 0 auto" src="@/assets/img/partner/downbutton.png"
        @click="changePanal" />
      <img v-if="showPanal === true" style="margin: 0 auto" src="@/assets/img/partner/upbutton.png"
        @click="changePanal" /> -->
    </div>
    <!-- 最近学习 -->
    <div v-if="list && list.length > 0" class="list">
      <div v-for="(item, index) in list" :key="index" :class="item.isLive === 1 ? 'activeItem' : 'activeItem item'">
        <div class="content">
          <img class="item-img" :src="item.courseImg" />
          <div class="item-title">
            <div class="item-text elp">{{ item.courseName }}</div>
            <div class="item-kcname ellipsis">{{ item.time }}</div>
          </div>
          <div class="not_started fles-shrink" v-if="item.isLive === 0">
            暂未开始
          </div>
          <div class="colleges-watch fles-shrink" v-else-if="item.isLive === 1" @click="detail(item)">
            立即观看
          </div>
          <div class="not_started fles-shrink" v-else-if="item.isLive === 2">
            回放生成中
          </div>
          <div class="colleges-watch fles-shrink" v-else-if="item.isLive === 3" @click="detail(item)">
            回放观看
          </div>
        </div>
      </div>
    </div>

    <!-- 暂无记录 -->
    <div v-if="!list || list.length == 0" class="nothing">
      <!-- <img class="nothing-img" src="@/assets/img/study/noLearn.png" /> -->
      <div class="nothing-text">暂无记录哦~</div>
    </div>
    <!-- <el-pagination
      class="pagination colleges-pagination"
      :total="total"
      :current-page="pageNum"
      :page-sizes="[8, 16, 24, 40]"
      :page-size="pageSize"
      :pager-count="5"
      background
      layout="total, sizes, prev, pager, next"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    /> -->
    <el-dialog title="" custom-class="dpPlay" :visible.sync="ifDialog" :show-close="false" width="80%" append-to-body>
      <iframe :src="`${href}/pages/videoRoom?videoId=${dialogList.id}&id=${dialogList ? dialogList.id : ''
        }&domainjwt=${domainjwt}&userId=${userId}&classId=${dialogList && dialogList.classId ? dialogList.classId : ''
        }`" width="100%" height="721px" frameborder="0" noResize />
    </el-dialog>
    <!-- 是否可以观看弹窗 -->
    <div v-if="showLookDetail" class="lookBg">
      <div class="ifLook">
        <div class="prompt">提示</div>
        <div class="contents">
          您当前正在<span>{{ lookDeatil ? lookDeatil.terminal : "" }}</span>观看<span>{{ lookDeatil ? lookDeatil.chapterName :
            ""
            }}</span>,请关闭后再行观看其他章节。
        </div>
        <div class="button" @click="closeLook">关闭</div>
      </div>
    </div>
  </div>
</template>
<script>
import Vue from "vue";
import { Know, courseCheckPlay } from "@/api/know";
const know = new Know();
import { getInfo, getToken, getDomainToken } from "@/api/cookies";
import { getUnderwayNewPcAllList } from "@/api/user";
export default {
  data() {
    return {
      type: "",
      list: [],
      userId: null,
      total: 0,
      pageSize: 8,
      pageNum: 1,
      datelist: [],
      showDateList: [],
      week: [
        "周一",
        "周二",
        "周三",
        "周四",
        "周五",
        "周六",
        "周日",
        "周一",
        "周二",
        "周三",
        "周四",
        "周五",
        "周六",
        "周日",
      ],
      chooseDay: new Date().getDate(),
      chooseMonth: new Date().getMonth() + 1,
      loading: null,
      ifDialog: false,
      href: process.env.VUE_APP_BASE_DpPlay,
      dialogList: {},
      domainjwt: getDomainToken(),
      lookDeatil: null,
      showLookDetail: false,
      showPanal: false,
    };
  },
  async created() {
    const now = new Date();
    const thisDay = new Date(now.getFullYear(), now.getMonth() + 1, 0);
    this.userId = localStorage.getItem("userId");
    await this.getDates();
  },
  methods: {
    // 选择日期
    chooseDate(item) {
      if (item) {
        this.chooseDay = item.day;
        this.chooseMonth = item.month;
        this.list = item.detail?.data.map((item) => {
          item.time =
            item.endTime.split(" ")[0].split("-")[1] +
            "月" +
            item.endTime.split(" ")[0].split("-")[2] +
            "日 " +
            item.startTime.split(" ")[1] +
            "-" +
            item.endTime.split(" ")[1];
          return item;
        });
      }
    },
    // 截取本月及上下月凑够42天填充日历面板
    async getDates() {
      const now = new Date();
      const thisDay = new Date(now.getFullYear(), now.getMonth() + 1, 0);
      const lastDay = new Date(now.getFullYear(), now.getMonth(), 0);
      const lastMonday = new Date(
        lastDay.getFullYear(),
        lastDay.getMonth(),
        lastDay.getDate() - lastDay.getDay() + 1
      );
      const num =
        thisDay.getDate() + (lastDay.getDate() - lastMonday.getDate() + 1);
      const nextDay = 42 - num;
      now.setMonth(now.getMonth() + 1);
      const startDate =
        lastDay.getFullYear() +
        "-" +
        (lastDay.getMonth() + 1) +
        "-" +
        lastMonday.getDate();
      const endDate =
        now.getFullYear() + "-" + (now.getMonth() + 1) + "-" + nextDay;
      let liveDate = [];
      await getUnderwayNewPcAllList(
        localStorage.getItem("userId"),
        startDate,
        endDate
      ).then((res) => {
        if (res.code === 0) {
          liveDate = res.data;
        }
      });
      for (let i = lastMonday.getDate(); i <= lastDay.getDate(); i++) {
        const date = {
          day: i,
          month: lastMonday.getMonth() + 1,
          year: lastMonday.getFullYear(),
          date:
            lastMonday.getFullYear() +
            "-" +
            (lastMonday.getMonth() + 1).toString().padStart(2, "0") +
            "-" +
            i.toString().padStart(2, "0"),
        };
        this.datelist.push(date);
      }
      for (let i = 1; i <= thisDay.getDate(); i++) {
        const date = {
          day: i,
          month: thisDay.getMonth() + 1,
          year: thisDay.getFullYear(),
          date:
            thisDay.getFullYear() +
            "-" +
            (thisDay.getMonth() + 1).toString().padStart(2, "0") +
            "-" +
            i.toString().padStart(2, "0"),
        };
        this.datelist.push(date);
      }
      for (let i = 1; i <= nextDay; i++) {
        const date = {
          day: i,
          month: now.getMonth() + 1,
          year: now.getFullYear(),
          date:
            now.getFullYear() +
            "-" +
            (now.getMonth() + 1).toString().padStart(2, "0") +
            "-" +
            i.toString().padStart(2, "0"),
        };
        this.datelist.push(date);
      }
      this.datelist = this.datelist.map((item) => {
        const match = liveDate.find((i) => i.time === item.date);

        if (match) {
          item.detail = match;
        }
        return item;
      });
      this.showDateList = this.datelist.slice(0, 14);
      this.changePanal()
    },
    padZero(num) {

      return num < 10 ? '0' + num : num;
    },




    changePanal() {
      const now = new Date();
      const thisDay = new Date(now.getFullYear(), now.getMonth() + 1, 0);
      if (this.showPanal) {
        this.showPanal = !this.showPanal;
        this.showDateList = this.datelist.slice(0, 14);
      } else {
        this.showPanal = !this.showPanal;
        this.showDateList = this.datelist;
      }

      this.chooseDate(

        this.showDateList.find(
          (i) =>
            i.date ==
            (thisDay.getFullYear() +
              "-" +
              this.padZero(now.getMonth() + 1) +
              "-" +
              this.padZero(new Date().getDate()))
        )
      );
    },
    // 获取学习列表
    closeLook() {
      this.showLookDetail = false;
    },
    // 进入课堂
    detail(row) {
      switch (row.isLive) {
        case 1:
          const sub = {
            id: row.id ? row.id : undefined,
            classId: row.classId ? row.classId : undefined,
            startTime: row.startTime,
            chapterName: row.chapterName,
            classProductLine: row.productLine,
          };
          const data = Vue.prototype.export(sub);
          courseCheckPlay({
            courseType: "1",
            userId: this.userId,
            chapterId: row.chapterId ? row.chapterId : undefined,
            source: 1,
          }).then((res) => {
            if (res.code == 0) {
              this.$router.push(`/livebofang?${data}`);
            } else {
              this.lookDeatil = res.msg;
              this.showLookDetail = true;
            }
          });

          break;
        case 2:
          if (row.isOutsourcing == 1) {
            this.ifDialog = true;
            this.dialogList = row;
          } else {
            const sub2 = {
              id: row.chapterId ? row.chapterId : undefined,
              uservideoConfig: row.recordsConfig
                ? row.recordsConfig
                : undefined,
              courseId: row.kId ? row.kId : undefined,
              classId: row.classId ? row.classId : undefined,
              coursePackageId: row.coursePackageId
                ? row.coursePackageId
                : undefined,
              typebs: 2,
              chapterName: row.chapterName ? row.chapterName : undefined,
              classProductLine: row.productLine,
            };
            const data2 = Vue.prototype.export(sub2);
            courseCheckPlay({
              courseType: "2",
              userId: this.userId,
              chapterId: row.chapterId ? row.chapterId : undefined,
              source: 1,
            }).then((res) => {
              if (res.code == 0) {
                this.$router.push(`/dianbofang?${data2}`);
              } else {
                this.lookDeatil = res.msg;
                this.showLookDetail = true;
              }
            });
          }

          break;
        case 3:
          const sub3 = {
            id: row.id ? row.id : undefined,
            classId: row.classId ? row.classId : undefined,
            startTime: row.startTime,
            chapterName: row.chapterName,
            classProductLine: row.productLine,
          };
          const data1 = Vue.prototype.export(sub3);
          courseCheckPlay({
            courseType: "1",
            userId: this.userId,
            chapterId: row.chapterId ? row.chapterId : undefined,
            source: 1,
          }).then((res) => {
            if (res.code == 0) {
              this.$router.push(`/livebofang?${data1}`);
            } else {
              this.lookDeatil = res.msg;
              this.showLookDetail = true;
            }
          });
          break;
        case 4:
          this.$router.push(`/openclassxq?id=${row.classId}`);
          break;
        case 5:
          this.$router.push(
            `/ketangziliao?id=${row.kId}&classId=${row.classId}`
          );
          break;
        default:
          break;
      }
    },
  },
};
</script>
<style lang="less" scoped>
::v-deep .el-badge__content.is-fixed.is-dot {
  right: 0px;
  top: 4px;
}

.foxRight {
  width: 100%;
  padding-bottom: 20px;
  margin-top: 20px;
  height: 100%;

  .recentLiveTime {
    width: 1050px;

    .navBar {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 1050px;
      height: 44px;
      background: #dbedff;
      border-radius: 6px 6px 0px 0px;

      .week_item {
        display: flex;
        align-items: center;
        justify-content: center;
        width: calc(100% / 14);
        font-size: 16px;
        font-family: Source Han Sans CN-Normal, Source Han Sans CN;
        font-weight: 400;
        color: #333333;
      }
    }

    .dateBar {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      width: 1050px;
      background: #ffffff;
      flex-wrap: wrap;
      border-radius: 0px 0px 6px 6px;
      box-shadow: 0px 3px 6px 1px rgba(0, 0, 0, 0.1);

      .week_item {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 52px;
        width: calc(100% / 14);
        font-size: 16px;
        font-family: Source Han Sans CN-Normal, Source Han Sans CN;
        font-weight: 400;
        color: #333333;
      }
    }
  }

  //隐藏滚动条
  ::-webkit-scrollbar {
    width: 0 !important;
    scrollbar-width: none !important;
    -ms-overflow-style: none;
  }
}

.active {
  background: #2586f5;
  border-radius: 50%;
  width: 36px;
  margin: 0px 10px;
  height: 36px;
  text-align: center;
  line-height: 36px;
  color: #ffffff;
}

.no-this-month {
  color: #999999;
}

.list {
  width: 100%;
  margin-top: 21px;
  background-color: #fff;

  .item {
    width: 1049px;
    opacity: 1;
    // padding: 10px;
    margin-bottom: 20px;

    .content {
      display: flex;
      height: 114px;
      align-items: center;

      .item-img {
        width: 205px;
        height: 114px;
        border-radius: 6px;
        opacity: 1;
        flex-shrink: 0;
        margin-right: 12px;
      }

      .item-title {
        width: calc(100% - 204px - 100px);
        height: 100%;
        position: relative;

        .item-text {
          height: 16px;
          font-size: 16px;
          font-family: Microsoft YaHei-Regular, Microsoft YaHei;
          font-weight: 400;
          color: #333333;
          line-height: 16px;
          margin-top: 20px;
        }

        .item-kcname,
        .data {
          font-size: 16px;
          font-family: Microsoft YaHei-Regular, Microsoft YaHei;
          font-weight: 400;
          color: #666666;
          line-height: 16px;
          height: 16px;
        }

        .item-kcname {
          margin-top: 28px;
        }

        .data {
          position: absolute;
          bottom: 20px;
          font-size: 14px;
          font-family: Source Han Sans CN-Regular, Source Han Sans CN;
          font-weight: 400;
          color: #006de7;
        }
      }

      .colleges-learn {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 110px;
        height: 36px;
        border-radius: 18px 18px 18px 18px;
        opacity: 1;
        border: 1px solid #006de7;
        font-size: 14px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #006de7;
        cursor: pointer;
      }

      .colleges-watch {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 110px;
        height: 36px;
        border-radius: 18px 18px 18px 18px;
        opacity: 1;
        border: 1px solid #006de7;
        font-size: 14px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #006de7;
        cursor: pointer;
      }

      .colleges-watch:hover {
        width: 110px;
        height: 36px;
        background: linear-gradient(360deg, #0270e8 0%, #21b4f5 100%);
        border-radius: 20px 20px 20px 20px;
        opacity: 1;
        color: #ffffff;
        cursor: pointer;
      }

      .not_started {
        width: 110px;
        height: 36px;
        opacity: 1;
        border: 1px solid #b9bfc4;
        font-size: 14px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #777a82;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 36px;
        cursor: pointer;
      }
    }
  }

  .activeItem {
    width: 1040px;
    height: 135px;
    background: #f8f9fa;
    border-radius: 0px 0px 0px 0px;
    opacity: 1;
    padding: 10px 42px 10px 20px;
    margin-bottom: 20px;

    .content {
      display: flex;
      height: 114px;
      align-items: center;

      .item-img {
        width: 205px;
        height: 114px;
        border-radius: 6px;
        opacity: 1;
        flex-shrink: 0;
        margin-right: 12px;
      }

      .item-title {
        width: calc(100% - 204px - 100px);
        height: 100%;
        position: relative;

        .item-text {
          height: 16px;
          font-size: 16px;
          font-family: Microsoft YaHei-Regular, Microsoft YaHei;
          font-weight: 400;
          color: #333333;
          line-height: 16px;
          margin-top: 20px;
        }

        .item-kcname,
        .data {
          font-size: 16px;
          font-family: Microsoft YaHei-Regular, Microsoft YaHei;
          font-weight: 400;
          color: #666666;
          line-height: 16px;
          height: 16px;
        }

        .item-kcname {
          margin-top: 28px;
        }

        .data {
          position: absolute;
          bottom: 20px;
          font-size: 14px;
          font-family: Source Han Sans CN-Regular, Source Han Sans CN;
          font-weight: 400;
          color: #006de7;
        }
      }

      .colleges-learn {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 110px;
        height: 36px;
        border-radius: 18px 18px 18px 18px;
        opacity: 1;
        border: 1px solid #006de7;
        font-size: 14px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #006de7;
      }

      .colleges-watch {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 110px;
        height: 36px;
        border-radius: 18px 18px 18px 18px;
        opacity: 1;
        border: 1px solid #006de7;
        font-size: 14px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #006de7;
      }

      .colleges-watch:hover {
        width: 110px;
        height: 36px;
        background: linear-gradient(360deg, #0270e8 0%, #21b4f5 100%);
        border-radius: 20px 20px 20px 20px;
        opacity: 1;
        color: #ffffff;
      }

      .not_started {
        width: 110px;
        height: 36px;
        opacity: 1;
        border: 1px solid #b9bfc4;
        font-size: 14px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #777a82;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 36px;
      }
    }
  }
}

.lookBg {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;

  .ifLook {
    width: 500px;
    background-color: #fff;
    padding: 10px;
    border-radius: 10px;
    border: 1px solid #ccc;

    .contents {
      width: 380px;
      margin: 20px auto;

      &>span {
        color: red;
      }
    }

    .button {
      width: 120px;
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0 auto;
      border-radius: 10px;
      border: 1px solid #ccc;
      cursor: pointer;
    }
  }
}
</style>
